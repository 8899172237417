export const TIPOS_EMAIL_CADASTRO = [
  {
    nome: "@sme.prefeitura.sp.gov.br",
    uuid: 0,
  },
  {
    nome: "@prefeitura.sp.gov.br",
    uuid: 1,
  },
];

export const TABS = {
  ESCOLA: "ESCOLA",
  DRE_CODAE: "DRE/CODAE",
  TERCEIRIZADAS: "EMPRESA",
  PRIMEIRO_ACESSO: "PRIMEIRO_ACESSO",
};
