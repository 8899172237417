import React from "react";

export const IconeEmpresa = (props) => {
  const { hover } = props;
  return (
    <svg
      width="86"
      height="86"
      viewBox="0 0 86 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M85.6773 16.3399L72.7773 0.530501C72.5058 0.195264 72.0978 0.00069987 71.6667 0H24.3667C21.992 0 20.0667 1.92534 20.0667 4.3V22.9333H22.9333V4.3C22.9333 3.50845 23.5751 2.86667 24.3667 2.86667H70.2333V14.3333C70.2333 16.708 72.1587 18.6333 74.5333 18.6333H83.1333V81.7C83.1333 82.4915 82.4915 83.1333 81.7 83.1333H24.3667C23.8543 83.134 23.3805 82.8604 23.1237 82.4167L20.6426 83.85C21.4132 85.1777 22.8311 85.9965 24.3667 86H81.7C84.0746 86 86 84.0747 86 81.7V17.2427C85.9993 16.9138 85.8852 16.5953 85.6773 16.3399ZM73.1 14.3333V5.46108L81.511 15.7667H74.5333C73.7418 15.7667 73.1 15.1249 73.1 14.3333Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M58.7667 75.9667V49.0203L59.5624 48.4898C59.9599 48.2245 60.1993 47.778 60.2 47.3V32.9667C60.2 32.1751 59.5582 31.5333 58.7667 31.5333H41.5667V27.2333C41.5667 26.4418 40.9249 25.8 40.1333 25.8H20.0667C19.2751 25.8 18.6333 26.4418 18.6333 27.2333V31.5333H1.43333C0.641781 31.5333 0 32.1751 0 32.9667V47.3C0 47.7794 0.239356 48.2266 0.637581 48.4926L1.43333 49.0203V75.9667C1.43333 78.3413 3.35868 80.2667 5.73333 80.2667H54.4667C56.8413 80.2667 58.7667 78.3413 58.7667 75.9667ZM21.5 28.6667H38.7V31.5333H21.5V28.6667ZM2.86667 34.4H57.3333V46.5329L49.7369 51.6H10.4631L2.86667 46.5329V34.4ZM25.8 54.4667H34.4V60.2H25.8V54.4667ZM4.3 75.9667V50.9337L9.23758 54.2301C9.47414 54.3855 9.75059 54.4674 10.0333 54.4667H22.9333V60.2C22.9333 61.7831 24.2169 63.0667 25.8 63.0667H34.4C35.9831 63.0667 37.2667 61.7831 37.2667 60.2V54.4667H50.1667C50.4494 54.466 50.7266 54.3827 50.9624 54.2259L55.9 50.9337V75.9667C55.9 76.7582 55.2582 77.4 54.4667 77.4H5.73333C4.94178 77.4 4.3 76.7582 4.3 75.9667Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M28.6667 55.9H31.5334V58.7667H28.6667V55.9Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M30.1 8.60001H32.9666V11.4667H30.1V8.60001Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M35.8333 8.60001H44.4333V11.4667H35.8333V8.60001Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M30.1 14.3333H44.4333V17.2H30.1V14.3333Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M75.9667 24.3667C75.9667 23.5751 75.3249 22.9333 74.5333 22.9333H44.4333V25.8H73.1V43H63.0667V45.8667H74.5333C75.3249 45.8667 75.9667 45.2249 75.9667 44.4333V24.3667Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M64.5 51.6H75.9667V54.4667H64.5V51.6Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M64.5 57.3333H75.9667V60.2H64.5V57.3333Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M64.5 63.0667H75.9667V65.9333H64.5V63.0667Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
      <path
        d="M64.5 68.8H75.9667V71.6667H64.5V68.8Z"
        fill={hover ? "#ffffff" : "#198459"}
      />
    </svg>
  );
};

export default IconeEmpresa;
