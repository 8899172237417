export const BUTTON_STYLE = {
  BLUE: "blue-button",
  BLUE_OUTLINE: "blue-button-outline",
  GREEN: "green-button",
  GREEN_OUTLINE: "green-button-outline",
  GREEN_OUTLINE_WHITE: "green-button-outline-white",
  RED: "red-button",
  RED_OUTLINE: "red-button-outline",
  GRAY_OUTLINE: "gray-button-outline",
  GRAY: "gray-button",
  DARK_OUTLINE: "dark-button-outline",
  ORANGE_OUTLINE: "orange-button-outline",
};

export const BUTTON_ICON = {
  ARROW_LEFT: "fas fa-arrow-left",
  ARROW_RIGHT: "fas fa-arrow-right",
  ANGLE_UP: "fa fa-angle-up",
  ANGLE_DOWN: "fa fa-angle-down",
  ATTACH: "fas fa-file-upload",
  CHART_BAR: "fas fa-chart-bar",
  CHART_LINE: "fas fa-chart-line",
  CHECK_CIRCLE: "fas fa-check-circle",
  CHECK_NORMAL: "fas fa-check",
  DOWNLOAD: "fas fa-download",
  EDIT: "fas fa-edit",
  FILE_EXCEL: "fas fa-file-excel",
  FILE_PDF: "fas fa-file-pdf",
  FILE_ALT: "far fa-file-alt",
  FILTER: "fas fa-filter",
  LOADING: "fas fa-spinner fa-spin",
  PLUS_CIRCLE: "fas fa-plus-circle",
  PLUS: "fas fa-plus",
  PRINT: "fas fa-print",
  SAIR: "fas fa-door-open",
  SHARE_SQUARE: "fas fa-share-square",
  SEARCH: "fas fa-search",
  TABLE: "fa-solid fa-table",
  TIMES: "fas fa-times",
  TIMES_CIRCLE: "fas fa-times-circle",
  TRASH: "fas fa-trash-alt",
  EYE: "fas fa-eye",
  PEN: "fas fa-pen",
  PAPER_CLIP: "fas fa-paperclip",
};

export const BUTTON_TYPE = {
  SUBMIT: "submit",
  BUTTON: "button",
  RESET: "reset",
  FILE: "file",
};
