const TIPOS_UNIDADES_GRUPO_1 = ["CEI", "CEI CEU", "CCI"];
const TIPOS_UNIDADES_GRUPO_2 = ["CEMEI", "CEU CEMEI"];
const TIPOS_UNIDADES_GRUPO_3 = [
  "EMEF",
  "CEU EMEF",
  "EMEFM",
  "CIEJA",
  "CEU GESTAO",
];
const TIPOS_UNIDADES_GRUPO_4 = ["EMEBS"];
const TIPOS_UNIDADES_GRUPO_5 = ["EMEI", "CEU EMEI"];

export const TIPOS_UNIDADES_GRUPOS = [
  TIPOS_UNIDADES_GRUPO_1,
  TIPOS_UNIDADES_GRUPO_2,
  TIPOS_UNIDADES_GRUPO_3,
  TIPOS_UNIDADES_GRUPO_4,
  TIPOS_UNIDADES_GRUPO_5,
];
