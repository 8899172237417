import { OptionsCategoria } from "./interfaces";

export const CATEGORIA_OPTIONS: OptionsCategoria[] = [
  {
    uuid: "PERECIVEIS",
    nome: "Perecíveis",
  },
  {
    uuid: "NAO_PERECIVEIS",
    nome: "Não Perecíveis",
  },
];
