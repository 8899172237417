import { PEDIDOS, FLUXO, AUTH_TOKEN } from "services/constants";
import { getPath } from "./helper";

export const terceirizadaListarSolicitacoesDeInclusaoDeAlimentacao = async (
  filtroAplicado,
  tipoSolicitacao
) => {
  const url = `${getPath(tipoSolicitacao)}/${
    PEDIDOS.TERCEIRIZADA
  }/${filtroAplicado}/`;
  const OBJ_REQUEST = {
    headers: AUTH_TOKEN,
    method: "GET",
  };
  try {
    const result = await fetch(url, OBJ_REQUEST);
    const status = result.status;
    const json = await result.json();
    return { results: json.results, status };
  } catch (error) {
    console.log(error);
  }
};

// TODO: confirmar necessidade do parametro
export const terceirizadaListarSolicitacoesDeInclusaoDeAlimentacaoReprovados = (
  tipoSolicitacao
) => {
  const url = `${getPath(tipoSolicitacao)}/pedidos-reprovados-terceirizada/`;
  const OBJ_REQUEST = {
    headers: AUTH_TOKEN,
    method: "GET",
  };
  return fetch(url, OBJ_REQUEST)
    .then((result) => {
      return result.json();
    })
    .catch((error) => {
      console.log(error);
    });
};

export const terceirizadaDarCienciaDeInclusaoDeAlimentacao = (
  uuid,
  tipoSolicitacao
) => {
  const url = `${getPath(tipoSolicitacao)}/${uuid}/${
    FLUXO.TERCEIRIZADA_TOMA_CIENCIA
  }/`;
  let status = 0;
  return fetch(url, {
    method: "PATCH",
    headers: AUTH_TOKEN,
  })
    .then((res) => {
      status = res.status;
      return res.json();
    })
    .then((data) => {
      return { data: data, status: status };
    })
    .catch((error) => {
      return error.json();
    });
};

export const terceirizadaResponderQuestionamentoDeInclusaoDeAlimentacao =
  async (uuid, payload, tipoSolicitacao) => {
    const url = `${getPath(tipoSolicitacao)}/${uuid}/${
      FLUXO.TERCEIRIZADA_RESPONDE_QUESTIONAMENTO
    }/`;
    const OBJ_REQUEST = {
      headers: AUTH_TOKEN,
      method: "PATCH",
      body: JSON.stringify(payload),
    };
    let status = 0;
    try {
      const res = await fetch(url, OBJ_REQUEST);
      const data = await res.json();
      status = res.status;
      return { ...data, status: status };
    } catch (error) {
      return error.json();
    }
  };
