import React from "react";

export const IconeAbastecimento = () => {
  return (
    <svg
      width="104"
      height="83"
      viewBox="0 0 104 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M97.3 57.0625V58.5625H98.8H101.4C102.005 58.5625 102.5 59.0615 102.5 59.6562V64.8438C102.5 65.4385 102.005 65.9375 101.4 65.9375H93.6H92.1V67.4375C92.1 75.1975 85.7875 81.5 78 81.5C70.2125 81.5 63.9 75.1975 63.9 67.4375V65.9375H62.4H41.6H40.1V67.4375C40.1 75.1975 33.7875 81.5 26 81.5C18.2125 81.5 11.9 75.1975 11.9 67.4375V65.9375H10.4H7.8C4.31877 65.9375 1.5 63.1203 1.5 59.6562V7.78125C1.5 4.31718 4.31877 1.5 7.8 1.5H59.8C63.2812 1.5 66.1 4.31718 66.1 7.78125V15.5625V17.0625H67.6H74.7663C76.4292 17.0625 78.0317 17.7291 79.2156 18.9102L95.4494 35.1049C96.6331 36.2858 97.3 37.8829 97.3 39.5385V57.0625ZM91 43H92.5V41.5V39.5385V38.9161L92.0594 38.4765L75.8256 22.2818L75.3865 21.8438H74.7663H67.6H66.1V23.3438V41.5V43H67.6H91ZM16.7 67.4375C16.7 72.5652 20.8687 76.7188 26 76.7188C31.1313 76.7188 35.3 72.5652 35.3 67.4375C35.3 62.3098 31.1313 58.1562 26 58.1562C20.8687 58.1562 16.7 62.3098 16.7 67.4375ZM68.7 67.4375C68.7 72.5652 72.8687 76.7188 78 76.7188C83.1313 76.7188 87.3 72.5652 87.3 67.4375C87.3 62.3098 83.1313 58.1562 78 58.1562C72.8687 58.1562 68.7 62.3098 68.7 67.4375Z"
        stroke="#A8A8A8"
        strokeWidth="3"
      />
    </svg>
  );
};
