import React, { Component } from "react";
import "./style.scss";

export class HeaderLogo extends Component {
  render() {
    return (
      <div className="header-logo">
        <img src="/assets/image/logo-sigpae-com-texto.png" alt="" />
      </div>
    );
  }
}
